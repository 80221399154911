<template>
    <div>
        You should not be here... these are not the droids you are looking for...

        Try selecting another option from the menu...
    </div>
</template>

<script>

export default {
    name: "auth-fail",
    props: [],
    components: {},
}

</script>